import {defineStore} from 'pinia'
import {NetworkService} from "~/utils/NetworkService";
import type {ParentRb, ParentInfo} from "~/models/request/parentRb";
import {ParentType} from "~/utils/enums/ParentType";
import type {AddUpdateStudentParentRelationRb} from "~/models/request/addUpdateStudentParentRelationRb";
import type {NotificationDetails} from "~/models/notificationDetails";
import {NotificationType} from "~/utils/enums/NotificationType";

export const parentStore = defineStore({
    id: 'parent-store',
    persist: true,
    state: () => {
        return {
            loggedInParentId: <number>0,
            loggedInUserParentMap: <any>{},
            studentIdList: <any>[]
        }
    },
    actions: {
        getAllParentsNetwork: async function (companyId:number) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/StudentParent/getAllStudentsAndParentsByCompanyId';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url,  {"companyId": companyId}, null, "getAllParentsNetwork" + new Date().getMilliseconds() + "_")
            return Result;
        }, addParent: async function (parent:ParentRb, doNotSendEmail:boolean) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Parent/addParent';
            const ns = new NetworkService();
            const sendEmail = ((parent.Email??'').toString().trim()>0) && !doNotSendEmail && [ParentType.PRIMARY, ParentType.GENERAL_PRIMARY].includes(parent.ParentType);
            let {Result} = await ns.post$fetch(url, {...parent, 'Id': parent.id, 'isUpdate': parent.id>0, 'sendEmail': sendEmail}, null, "addParent" + new Date().getMilliseconds() + "_")
            return Result;
        },
        addParentServer: async function (parent:ParentRb, doNotSendEmail:boolean) {
            let url = '/api/parent/add';
            const ns = new NetworkService();
            const sendEmail = ((parent.Email??'').toString().trim()>0) && !doNotSendEmail && [ParentType.PRIMARY, ParentType.GENERAL_PRIMARY].includes(parent.ParentType);
            let {IsOperationSuccessful} = await ns.post$fetch(url, {...parent, 'Id': parent.id, 'isUpdate': parent.id>0, 'sendEmail': sendEmail}, null, "addParentServer" + new Date().getMilliseconds() + "_", false, true)
            return IsOperationSuccessful;
        },
        addUpdateStudentParentMapping: async function (studentParentMappingRb:AddUpdateStudentParentRelationRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/StudentParent/AddUpdateStudentParentMapping';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, studentParentMappingRb, null, "addStudentParentRelationMapping" + new Date().getMilliseconds() + "_")
            return Result;
        },
        deleteParent: async function (companyId:number, studentList:number[], parentId:number) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/BusConnectService.svc/deleteparent';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {
                'companyId': companyId,
                'studentList': studentList,
                'neighborParentId': parentId,
            }, null, "addParent" + new Date().getMilliseconds() + "_")
            return Result;
        },
        getParentById: async function (parentId: number){
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Parent/getParentDetailsById';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {
                'ParentId': parentId,
            }, null, "addParent" + new Date().getMilliseconds() + "_")
            return Result;
        },
        createAssignUpdateParent: async function (parent: ParentInfo, studentIdList: number[], isUpdate: boolean, confarmation: number, noLogin: boolean) {
            console.log(parent)
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Parent/CreateAssignUpdateParent';
            const ns = new NetworkService();
            try{
                let response = await ns.post$fetch(url, {'parent': parent, 'studentIdList': studentIdList, 'relation': parent.relation,'isUpdate': isUpdate, 'confarmation': confarmation, 'noLogin': noLogin},
                    null, "addParent" + new Date().getMilliseconds() + "_")
                console.log(response)
                if(response.IsResult == true){
                    return response
                }
                else{
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.WARNING,
                        title: 'Error',
                        message: response?.InfoMessages[0]?.Text,
                    })

                }
            }
            catch(err){
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.WARNING,
                    title: 'Error',
                    message: 'Something went wrong',
                })
            }
        },
        assignLoggedInUserParentMap (parentMap: any, studentList: any[], parentId: number){
            this.loggedInUserParentMap = parentMap;
            this.studentIdList = studentList
            this.loggedInParentId = parentId
        },
    },
    getters: {
        getLoggedInUserParentMap: state => state.loggedInUserParentMap,
        getLoggedInUserStudentIds: state => state.studentIdList,
        getLoggedInParentId: state => state.loggedInParentId,
        getParentMapByParentId: state => function (parentId:number) {
            return state.loggedInUserParentMap[parentId]
        }
    },
})
